/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:9cda4dd9-27fe-43b4-bb34-1a67097a5029",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_wmFWGmlIc",
    "aws_user_pools_web_client_id": "11rq47fg6dkueudnm6qp85aqj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mxygtvpvgrcvvjgoqeree5ubvi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2hck7dnnlfbfzjxzphcx4vpna4",
    "aws_user_files_s3_bucket": "oishiiimages02902-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
