import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';

const MyTheme = {
  signInButtonIcon: { 'display': 'none' },
  signInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
}

function Login() {
  return (
    <div>
      <p>This is the home page</p>
      
    </div>
  );
}

export default withAuthenticator(Login, MyTheme);