import React from 'react';
import {  BrowserRouter as Router,  Switch,  Route,  Link} from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Home from './pages/home';
import Login from './pages/login';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    maxWidth: 1500,
    margin: 'auto',
    background: '#fff',
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    boxShadow: '4px 3px 7px 2px #4646462e',
    [theme.breakpoints.down('md')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  title: {
    margin: '6px 0 6px 16px',
   fontFamily: "'Potta One', cursive",
   fontSize: '2rem',
   color: 'inherit',
   textDecoration: 'none',
   [theme.breakpoints.down('xs')]: {
    fontSize: '1.25rem',
   }
  },
  button: {
    padding: 0
  },
  search: {
   margin: '8px auto 0',
   padding: 6,
   display: 'block',
   '& svg': {
    width: '2.5rem',
    height: '2.5rem'
   },
   [theme.breakpoints.down('xs')]: {
     position: 'absolute',
     bottom: 0,
     left: 'calc(50vw - 26px)'
   },
  },
  headerOptions: {
    padding: '16px 16px 0 0',
    textAlign: 'right'
  },
  inspiration: {
    marginRight: 30
  },
  menuButton: {
    float: 'right'
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function App() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  }

  const mobileLinks = [
    { label: 'My Account', to: '/MyAccount/' },
    { label: 'Recipe Lists', to: '/RecipeLists/' },
    { label: 'Inspirations', to: '/Inspirations/' },
    { label: 'Go Premium', to: '/Premium/' },
    { label: 'Help', to: '/Help/' },
  ]

  const drawer = (
    <div>
      <List>
        {mobileLinks.map((link) => (
          <ListItem key={link.to}>
            <Button
              component={Link}
              className={classes.drawerButton}
              to={link.to}>
                {link.label}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Router>
      <div className="App">
        <header className={classes.header}>
          <Grid container spacing={0}>
            <Grid item xs={9} sm={5}>
                <Button component={Link} className={classes.button} to='/'><span className={classes.title}>Oishii Cooking</span></Button>
            </Grid>
            <Grid item xs={false} sm={2}>
                <IconButton component={Link} className={classes.search} color="primary" aria-label="search" to='/Search/'>
                  <SearchIcon />
                </IconButton>
            </Grid>
            <Grid item xs={3} sm={5}>
              <Box display={{ xs: 'block', sm: 'none' }}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  variant="temporary"
                  anchor='right'
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Box>
              <Box display={{ xs: 'none', sm: 'block' }}>
                <div className={classes.headerOptions}>
                  <Button component={Link} className={classes.inspiration} to='/Inspirations/'>Inspirations</Button>
                  {/*<Menu
                    className='joinMenu'
                    label={<Box direction="row" gap="small">
                        <Avatar background="accent-2">
                          <UserFemale color="accent-1" />
                        </Avatar>
                        <span className='joinText'>Join | Login</span>
                      </Box>}
                      items={[
                        { label: <Link to='/'>My Account</Link> },
                        { label: <Link to='/'>Recipe Lists</Link> },
                        { label: <Link to='/'>Go Premium</Link> },
                        { label: <Link to='/'>Help</Link> },
                    ]}
                  />*/}
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Join | Login
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem>
                      <ListItemIcon>
                        <SendIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Account" />
                    </StyledMenuItem>
                    <StyledMenuItem>
                      <ListItemIcon>
                        <DraftsIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Recipe Lists" />
                    </StyledMenuItem>
                    <StyledMenuItem>
                      <ListItemIcon>
                        <InboxIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Go Premium" />
                    </StyledMenuItem>
                    <StyledMenuItem>
                      <ListItemIcon>
                        <InboxIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Help" />
                    </StyledMenuItem>
                  </StyledMenu>
                </div>
              </Box>
            </Grid>
          </Grid>
        </header>
        <Switch>
            <Route path="/Join/">
              <Login />
            </Route>
            <Route path="/Search/">
              <p>Search Here</p>
            </Route>
            <Route path="/Inspirations/">
            </Route>
            <Route path="/Categories/">
            </Route>
            <Route path="/Recipe/:recipeSeo/">
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
      </div>
    </Router>
  );
}

export default App;