import React from 'react';

function Home() {

  return (
    <div>
      <p>This is the home page</p>
      
    </div>
  );
}

export default Home;